import { PageMetaDefaults } from '@/components/global/Head';
import Page, { getPageProps, PageProps } from '@/components/global/page';
import pageQuery from '@/queries/page/getVideoPageByUri.graphql';
import { CmsSite } from '@/types/pbskids-graph';

export async function getServerSideProps(context: { params: { 'uriParts': string[]; }; }) {
  return getPageProps(
    context,
    pageQuery,
    CmsSite.VideosWebEn,
    PageMetaDefaults.DEFAULT_VIDEO_PAGE_DESCRIPTION,
    {
      verb: 'Watch',
      noun: 'Videos',
    },
    'video',
  );
}

export default function VideoPage(props: PageProps) {
  // eslint-disable-next-line new-cap
  return Page(props);
}
